import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";

import "firebase/firestore";

import config from "../src/Configs/config";

const fbc = JSON.parse(config.fbConfig);

const firebaseApp = firebase.initializeApp(fbc);

const auth = firebaseApp.auth();
const db = firebaseApp.firestore();
const analytics = firebaseApp.analytics();


export {
    db,
    auth,
    analytics,
};