import React from "react";

import Wrapper from './Wrapper.style';

function Emoji({onClick} ) {
    const emojiSet = ['🤬', '😡', '😠', '😐', '🙂', '😀', '😃'];

    return (
        <Wrapper>
            {emojiSet.map((el, i) => {
                return <div tabIndex={0} role='button' key={i} onClick={(event) => onClick(event, el, 'emoji')}>{el}</div>
            })}
        </Wrapper>
    );
}

export default Emoji;
