import styled from 'styled-components';

const GlobalStyle = styled.div`
    .title {
      font-family: 'Dela Gothic One', cursive;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      color: ${({ theme }) => theme.colors.primary};
    }

   .loading {
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 2px solid rgb(255 255 255 / 40%);
        border-radius: 50%;
        border-top-color: #fff;
        animation: spin 1s linear infinite;
   }
    
   @keyframes spin {
        to {
          transform: rotate(360deg);
        }
   }
  
    background: ${({ theme }) => theme.colors.pageBG};
    min-height: 100vh;
`;

export default GlobalStyle;