import * as React from "react";

const getSentiment = (sentiment) => {
    if(sentiment === undefined) {
        return <div></div>;
    }

    if (sentiment <= -0.8) {
        return <div>🤬</div>;
    } else if (sentiment >= -0.7 && sentiment <= -0.5) {
        return <div>😡</div>;
    } else if (sentiment >= -0.4 && sentiment <= -0.2) {
        return <div>😠</div>;
    } else if (sentiment >= -0.1 && sentiment <= 0.1) {
        return <div>😐</div>;
    } else if (sentiment >= -0.2 && sentiment <= 0.4) {
        return <div>🙂</div>;
    } else if (sentiment >= -0.5 && sentiment <= 0.7) {
        return <div>😀</div>;
    } else if (sentiment >= -0.8) {
        return <div>😃</div>;
    }
};

export default getSentiment;