import React from "react";

import Wrapper from './Wrapper.style';

const RuleWarning = ({ text }) => {

    return (
        <Wrapper>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.3806 13.9528C19.6901 14.2624 19.9357 14.6298 20.1032 15.0342C20.2708 15.4386 20.3569 15.8721 20.3569 16.3098C20.3569 16.7476 20.2706 17.181 20.1032 17.5854C19.9356 17.9898 19.6901 18.3573 19.3805 18.6669C19.071 18.9764 18.7036 19.222 18.2992 19.3894C17.8946 19.5569 17.4613 19.6432 17.0236 19.6432C16.5857 19.6432 16.1524 19.5569 15.7478 19.3893C15.3434 19.2218 14.976 18.9764 14.6665 18.6668" stroke="#8A53E2" strokeWidth="2" strokeLinecap="round"/>
                <path d="M16.0004 8C10.7204 8 6.68189 11.0327 3.30102 15.1965C2.92054 15.6651 2.92047 16.3348 3.30094 16.8033C4.24983 17.972 5.25053 19.0516 6.31594 20M10.3209 22.7131C12.0463 23.5327 13.9277 24 16.0004 24C21.2804 24 25.3189 20.9673 28.6997 16.8035C29.0802 16.3349 29.0802 15.6652 28.6997 15.1965C26.7992 12.8558 24.6908 10.8726 22.2709 9.58459" stroke="#8A53E2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M26.8291 4.15393L4 27.9994" stroke="#8A53E2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>

            <p className="text">
                {text}
            </p>
        </Wrapper>
    );
}

export default RuleWarning;
