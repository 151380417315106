import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    background: rgba(56, 173, 106, 0.15);
    border-radius: 10px;
    margin-top: auto;
    margin-bottom: 23px;
  .text {
    margin-top: 10px;
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: rgba(56, 173, 106, 1);
  }
`;

export default Wrapper;