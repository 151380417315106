import styled from 'styled-components';

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    padding: 14px 0;
    background: #FFFFFF;
    box-shadow: 0 10px 24px rgba(29, 42, 68, 0.12);
    width: 100%;
    z-index: 2;

    .user-panel {
      display: flex;
      .user-info {
        padding-right: 32px;
        display: flex;
        .name {
          font-family: 'Manrope', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          display: flex;
          align-items: center;
          color: #AAAAAA;
        }
        .email {
          font-family: 'Manrope', sans-serif;
          font-style: normal;
          font-weight: 800;
          font-size: 14px;
          line-height: 19px;
          display: flex;
          align-items: center;
          color: #131415;
        }
      }
    }
  
   .logout {
     display: flex;
     align-items: center;
     height: 32px;
     padding: 0 16px;
     background: rgba(223, 56, 56, 0.1);
     border-radius: 5px;
     flex: none;
     order: 1;
     flex-grow: 0;
     font-family: 'Manrope', sans-serif;
     font-style: normal;
     font-weight: 600;
     font-size: 15px;
     line-height: 20px;
     text-align: center;
     color: #DF3838;

   }
`;

export default Wrapper;