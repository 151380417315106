import styled from 'styled-components';

const StyleWrapper = styled.div`
  padding: 15px 15px 64px 15px;
  min-height: 100vh;

  .logo-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 35px;
    .sub-logo {
      font-family: 'Manrope', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 17px;
      line-height: 23px;
      text-align: center;
      margin-top: 25px;
      margin-bottom: 25px;
      color: ${({ theme }) => theme.colors.secondary};
    }
  }
  
  .controls-group {
    padding: 25px 0;
    a {
      color: ${({ theme }) => theme.colors.primary};
      font-family: 'Manrope', sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 15px;
      line-height: 20px;
    }
    
    &.controls {
      background: ${({ theme }) => theme.colors.infoArea};
      border-radius: 10px;
      padding: 25px 15px;
    }
    &:last-child {
      border-bottom: 0;
    }
    .label {
      font-family: 'Manrope', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      margin-bottom: 4px;

      color: #888888;
      &.xs {
        font-size: 12px;
        line-height: 16px;
        text-align: center;
      }
    }
    label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      p {
        font-family: 'Manrope', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        color: ${({ theme }) => theme.colors.secondary};
        span {
          color: ${({ theme }) => theme.colors.primary};
        }
      } 
    }
    .field {
      position: relative;
      .error {
        position: absolute;
        left: 0;
        top: calc(100% + 5px);
        font-family: 'Manrope', sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 12px;
        line-height: 14px;
        color: red;
      }
    }
    button {
      height: 50px;
      width: 100%;
      background: ${({ theme }) => theme.colors.primary};
      border-radius: 8px;
      font-family: 'Manrope', sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 15px;
      line-height: 20px;
      color: #FFFFFF;
    }
  }

  input {
    width: 100%;
    border: 1.5px solid ${({ theme }) => theme.colors.primary};
    background: ${({ theme }) => theme.colors.inputBg};
    color: ${({ theme }) => theme.colors.secondary};
    box-sizing: border-box;
    border-radius: 10px;
    outline: 0;
    height: 44px;
    padding: 5px 17px;
    font-style: normal;
    font-weight: 500;
    font-size: 16.675px;
    line-height: 23px;
    letter-spacing: 0.02em;
  }

  .checkbox-wrapper {
    width: 22px;
    display: block;
    position: relative;
    cursor: pointer;
    height: 22px;
    font-size: 22px;
    user-select: none;
    input {
      display: none;
    }
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 22px;
      width: 22px;
      border-radius: 4px;
      border: 1.5px solid ${({ theme }) => theme.colors.primary};
      background: ${({ theme }) => theme.colors.inputBg};
      &:after {
        content: "";
        position: absolute;
        display: none;
        left: 7px;
        top: 2px;
        width: 6px;
        height: 12px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
    
    input:checked ~ .checkmark {
      background-color: ${({ theme }) => theme.colors.primary};
    }
    
    input:checked ~ .checkmark:after {
      display: block;
    }
  }
  
`;

export default StyleWrapper;