import React, {useEffect, useState} from "react";

import { db } from "../../firebase";

import Wrapper from './Wrapper.style';

import commentIcon from '../../Assets/Images/commentIcon.png';
import getSentiment from "../../Helpers/getSentiment";
import { useParams } from "react-router-dom";

const ChatMessageStatistic = ({ msg }) => {
    const {createdAt, docId } = msg;

    const [messages, setMessages] = useState([]);
    let { meetingId } = useParams();

    useEffect(() => {
        db.collection('meetings')
            .doc(`${meetingId}`)
            .collection('comments')
            .doc(`${docId}`)
            .collection('messages')
            .orderBy('createdAt', "desc")
            .onSnapshot( snapshot => {

                const arr = snapshot.docs.map( doc => {

                    return doc.data();
                });

                setMessages(arr);
            });
    }, [docId]);

    const emojiArr = messages.filter(el => el.type === 'emoji').map(it => it.text);
    const msgArr = messages.filter(el => el.type === 'message');

    const emojiCounts = emojiArr && emojiArr.reduce((acc, value) => ({
        ...acc,
        [value]: (acc[value] || 0) + 1
    }), {});

    return (
        <Wrapper>
            <div className="time-wrapper">
                <div className="time">
                    {createdAt}
                    <div className="point"/>
                </div>
            </div>
            <div>
                <div className="emoji-wrapper">
                    {Object.keys(emojiCounts).sort().map((keyName, i) => (
                        <div className={`emoji-box ${keyName}`} key={i}>
                            <div className="emoji">{keyName}</div>
                            <div className="count">{emojiCounts[keyName]}</div>
                        </div>
                    ))}
                </div>
                {msgArr.length !== 0 && <div className="messages-wrapper">
                    <p className="box-description"><img src={commentIcon} alt="Anonymous comments"/>Anonymous comments</p>
                    {msgArr.map( (el, i )=> {
                        const { text } = el;
                        return <div className="message-box" key={text+i}>
                            <div className={`emoji-box  ${getSentiment(el.sentiment).props.children}`}>
                                <span>{getSentiment(el.sentiment)}</span>
                            </div>
                            <p>{text}</p>
                        </div>
                    })}
                </div>}
            </div>
        </Wrapper>
    );
}

export default ChatMessageStatistic;
