export const light = {
    name: "light-theme",
    colors: {
        pageBG: '#FFFFFF',
        boxBg: '#FFFFFF',
        infoArea: 'rgba(138, 83, 226, 0.1)',
        inputBg: '#FFFFFF',
        primary: "#8A53E2",
        primaryGray: "#888888",
        secondary: 'rgba(0, 0, 0, 1)',
        navBG: "#FFFFFF",
        navShadow: "0px -6px 20px rgba(29, 42, 68, 0.08)",
        warning: 'rgba(223, 56, 56, 1)',

        '🤬': 'rgba(255, 0, 21, 0.2)',
        '😡': 'rgba(255, 0, 21, 0.1)',
        '😠': 'rgba(255, 198, 50, 0.25)',
        '😐': 'rgba(251, 241, 0, 0.2)',
        '🙂': 'rgba(255, 198, 50, 0.25)',
        '😀': 'rgba(10, 182, 58, 0.15)',
        '😃': 'rgba(10, 182, 58, 0.2)',

    },
};

export const dark = {
    name: "dark-theme",
    colors: {
        pageBG: 'rgb(18, 18, 18)',
        infoArea: 'linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #121212',
        boxBg: 'linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #121212',
        inputBg: 'linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #121212',
        primary: "#9C5EFF",
        primaryGray: "#888888",
        secondary: 'rgba(255, 255, 255, 1)',
        navBG: "linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #121212",
        navShadow: "0px -6px 20px rgba(41, 29, 68, 0.08)",
        warning: 'rgba(223, 56, 56, 1)',

        '🤬': 'rgba(148, 40, 49, 0.5)',
        '😡': 'rgba(130, 61, 67, 0.5)',
        '😠': 'rgba(156, 127, 53, 0.5)',
        '😐': 'rgba(154, 149, 28, 0.5)',
        '🙂': 'rgba(156, 127, 53, 0.5)',
        '😀': 'rgba(10, 182, 58, 0.4)',
        '😃': 'rgba(10, 182, 58, 0.5)',

    },
};
