import React from "react";

import { analytics } from "../../firebase";

import { NavLink } from "react-router-dom";

import Wrapper from './Wrapper.style';

function Navigation() {

    const onNavClick = it => {
        const meetingId = localStorage.getItem('meetingId');

        analytics.logEvent(`${it}`, { meetingId });
    }

    return (
        <Wrapper>
            <NavLink to='/actions'>
                <svg onClick={()=> onNavClick('webex_actions_page')} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.4" d="M14 5V10H13C11.35 10 10 11.35 10 13V14H5C3.34 14 2 12.66 2 11V5C2 3.34 3.34 2 5 2H11C12.66 2 14 3.34 14 5Z" fill="#8A53E1"/>
                    <path d="M5.19952 6.63014C4.96952 6.63014 4.72952 6.52013 4.58952 6.31013C4.34952 5.97013 4.42952 5.50015 4.76952 5.26015C5.54952 4.71015 6.59951 4.70013 7.38951 5.24013C7.72951 5.47013 7.81952 5.94017 7.58952 6.28017C7.35952 6.62017 6.88951 6.71012 6.54951 6.48012C6.27951 6.30012 5.91952 6.30013 5.64952 6.49013C5.49952 6.59013 5.34952 6.63014 5.19952 6.63014Z" fill="#8A53E1"/>
                    <path d="M9.19952 6.63014C8.96952 6.63014 8.72952 6.52013 8.58952 6.31013C8.34952 5.97013 8.42952 5.50015 8.76952 5.26015C9.54952 4.71015 10.5995 4.70013 11.3895 5.24013C11.7295 5.47013 11.8195 5.94017 11.5895 6.28017C11.3595 6.62017 10.8895 6.71012 10.5495 6.48012C10.2795 6.30012 9.91952 6.30013 9.64952 6.49013C9.49952 6.59013 9.34952 6.63014 9.19952 6.63014Z" fill="#8A53E1"/>
                    <path d="M8.16078 12.1699H5.84078C5.13078 12.1699 4.55078 11.5899 4.55078 10.8799C4.55078 8.97994 6.10078 7.42993 8.00078 7.42993C8.78078 7.42993 9.55078 7.69994 10.1608 8.18994C10.4808 8.44994 10.5408 8.91993 10.2808 9.23993C10.0208 9.55993 9.55078 9.61992 9.23078 9.35992C8.88078 9.07992 8.46078 8.92993 8.01078 8.92993C7.01078 8.92993 6.18078 9.68992 6.07078 10.6699H8.17078C8.58078 10.6699 8.92078 11.0099 8.92078 11.4199C8.92078 11.8299 8.57078 12.1699 8.16078 12.1699Z" fill="#8A53E1"/>
                    <path d="M18.2207 16.6801C18.1507 16.6001 18.0507 16.5601 17.9407 16.5601H14.0607C13.9507 16.5601 13.8507 16.6001 13.7807 16.6801C13.7107 16.7601 13.6707 16.87 13.6907 16.97C13.8207 18.15 14.8107 19.05 16.0007 19.05C17.1907 19.05 18.1807 18.16 18.3107 16.97C18.3207 16.86 18.2907 16.7601 18.2207 16.6801Z" fill="#8A53E1"/>
                    <path d="M19 10H13C11.35 10 10 11.35 10 13V19C10 20.65 11.35 22 13 22H19C20.65 22 22 20.65 22 19V13C22 11.35 20.65 10 19 10ZM12.59 13.17C12.83 12.83 13.3 12.75 13.64 12.99C13.91 13.18 14.27 13.18 14.54 13C14.88 12.76 15.35 12.85 15.58 13.2C15.81 13.54 15.73 14.01 15.38 14.24C14.99 14.5 14.54 14.64 14.09 14.64C13.62 14.64 13.16 14.5 12.77 14.22C12.43 13.97 12.35 13.5 12.59 13.17ZM16 20.17C14.1 20.17 12.55 18.62 12.55 16.72C12.55 16.01 13.13 15.43 13.84 15.43H18.16C18.87 15.43 19.45 16.01 19.45 16.72C19.45 18.62 17.9 20.17 16 20.17ZM19.38 14.23C18.99 14.49 18.54 14.63 18.09 14.63C17.62 14.63 17.16 14.49 16.77 14.21C16.43 13.97 16.35 13.5 16.59 13.16C16.83 12.82 17.3 12.74 17.64 12.98C17.91 13.17 18.27 13.17 18.54 12.99C18.88 12.75 19.35 12.84 19.58 13.19C19.81 13.54 19.72 14 19.38 14.23Z" fill="#8A53E1"/>
                </svg>

                Actions
            </NavLink>
            <NavLink to='/statistics'>
                <svg onClick={()=> onNavClick('webex_statistics_page')} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22 22H2C1.59 22 1.25 21.66 1.25 21.25C1.25 20.84 1.59 20.5 2 20.5H22C22.41 20.5 22.75 20.84 22.75 21.25C22.75 21.66 22.41 22 22 22Z" fill="#AEAEAE"/>
                    <path d="M9.75 4V22H14.25V4C14.25 2.9 13.8 2 12.45 2H11.55C10.2 2 9.75 2.9 9.75 4Z" fill="#AEAEAE"/>
                    <path opacity="0.4" d="M3 10V22H7V10C7 8.9 6.6 8 5.4 8H4.6C3.4 8 3 8.9 3 10Z" fill="#AEAEAE"/>
                    <path opacity="0.4" d="M17 15V22H21V15C21 13.9 20.6 13 19.4 13H18.6C17.4 13 17 13.9 17 15Z" fill="#AEAEAE"/>
                </svg>
                Statistics
            </NavLink>
            <NavLink to='/settings'>
                <svg onClick={()=> onNavClick('webex_settings_page')} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.4" d="M2 12.8799V11.1199C2 10.0799 2.85 9.21994 3.9 9.21994C5.71 9.21994 6.45 7.93994 5.54 6.36994C5.02 5.46994 5.33 4.29994 6.24 3.77994L7.97 2.78994C8.76 2.31994 9.78 2.59994 10.25 3.38994L10.36 3.57994C11.26 5.14994 12.74 5.14994 13.65 3.57994L13.76 3.38994C14.23 2.59994 15.25 2.31994 16.04 2.78994L17.77 3.77994C18.68 4.29994 18.99 5.46994 18.47 6.36994C17.56 7.93994 18.3 9.21994 20.11 9.21994C21.15 9.21994 22.01 10.0699 22.01 11.1199V12.8799C22.01 13.9199 21.16 14.7799 20.11 14.7799C18.3 14.7799 17.56 16.0599 18.47 17.6299C18.99 18.5399 18.68 19.6999 17.77 20.2199L16.04 21.2099C15.25 21.6799 14.23 21.3999 13.76 20.6099L13.65 20.4199C12.75 18.8499 11.27 18.8499 10.36 20.4199L10.25 20.6099C9.78 21.3999 8.76 21.6799 7.97 21.2099L6.24 20.2199C5.33 19.6999 5.02 18.5299 5.54 17.6299C6.45 16.0599 5.71 14.7799 3.9 14.7799C2.85 14.7799 2 13.9199 2 12.8799Z" fill="#AEAEAE"/>
                    <path d="M12 15.25C13.7949 15.25 15.25 13.7949 15.25 12C15.25 10.2051 13.7949 8.75 12 8.75C10.2051 8.75 8.75 10.2051 8.75 12C8.75 13.7949 10.2051 15.25 12 15.25Z" fill="#AEAEAE"/>
                </svg>
                Settings
            </NavLink>
        </Wrapper>
    );
}

export default Navigation;
