import React, { useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";

import config from "../../../src/Configs/config";

import StyleWrapper from './StyleWrapper.style';

import { auth, analytics } from '../../firebase';

import logo from '../../Assets/Images/logo.png';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import authChat from "../../Assets/Images/authChat.png";
import authStatistics from "../../Assets/Images/authStatistics.png";
import AuthMessage from "../../Components/AuthMessage/AuthMessage";

function SignUp() {
    const navigate = useNavigate();
    const user = localStorage.getItem('user');

    if (user) {
        navigate("/meetings");
    }

    useEffect(() => {

        auth.onAuthStateChanged(authUser => {

            if(authUser) {
                authUser.getIdTokenResult().then(function(idTokenResult) {
                    localStorage.setItem('user', JSON.stringify(idTokenResult));
                    analytics.logEvent('sign_up');

                    navigate("/meetings");
                });
            }

        });
    }, []);

    const logInWithEmailAndPassword = ( fields, actions ) => {
        const { email, password } = fields;

        fetch(`${config.apiUrl}/anilive/portal/signup`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify({ email, password }),
        })
            .then((response) => response.json())
            .then(json => {
                const { status, error = 'An error' } = json;

                if (status === 'success') {
                    actions.setSubmitting(false);
                    actions.resetForm();
                    actions.setStatus(true);
                } else {
                    actions.setSubmitting(false);
                    actions.setErrors({request: error});
                }

            })
            .catch(json => {
                const { status, error = 'An error' } = json.data;

                if (status === 'success') {
                    actions.setSubmitting(false);
                    actions.resetForm();
                    actions.setStatus(true);
                } else {
                    actions.setSubmitting(false);
                    actions.setErrors({request: error});
                }

            });
    };

    return (
        <StyleWrapper>
            <div className="side form-side">
                <div className="form-wrapper min-h-[504px]">
                    <img src={logo} alt="AniLive" width={190}/>

                    <Formik
                    initialValues={{
                        email: '',
                        password: '',
                        confirmPassword: ''
                    }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string()
                            .email('Email is invalid')
                            .required('Email is required'),
                        password: Yup.string()
                            .min(8, 'Password must be at least 8 characters')
                            .required('Password is required'),
                        confirmPassword: Yup.string()
                            .oneOf([Yup.ref('password'), null], 'Passwords must match')
                            .required('Confirm Password is required')
                    })}
                    onSubmit={(fields, actions) => {
                        actions.setSubmitting(true);
                        logInWithEmailAndPassword(fields, actions);
                    }}
                >
                    {({ errors, touched,isSubmitting, status }) => (
                        <Form>
                            {!status ? <>
                                <div className="form-header flex justify-between items-center">
                                    <h1 className='title'>Sign Up</h1>
                                    <Link to='/signin'>Login</Link>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">Email <span className='asterisk'>*</span></label>
                                    <Field name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                    <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">Password <span className='asterisk'>*</span></label>
                                    <Field name="password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                    <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="confirmPassword">Confirm Password <span className='asterisk'>*</span></label>
                                    <Field name="confirmPassword" type="password" className={'form-control' + (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')} />
                                    <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback" />
                                </div>
                                <div className="form-group pt-2.5">
                                    <button type="submit" className="btn btn-primary">
                                    <span>
                                        {isSubmitting && <div className="loading" /> }
                                        Sign Up
                                    </span>
                                    </button>
                                    {/*<button type="reset" className="btn btn-secondary">Reset</button>*/}
                                </div>
                                <p className="form-warning">
                                    {errors && errors.request}
                                </p>
                            </> :
                                <>
                                    <div className="form-header flex justify-between items-center">
                                        <h1 className='title'>Successful SignUp</h1>
                                    </div>
                                    <AuthMessage text='Registration was successful. Please check your email, and confirm it to be able to Login.' />
                                    <Link to='/signin'>
                                        <a className="text-sm underline hover:no-underline text-gray-400 font-bold">Back to Login page</a>
                                    </Link>
                                </>
                            }
                        </Form>
                    )}
                </Formik>
            </div>
            </div>
            <div className="side banner-side">
                <div className="element-tl"/>
                <div className="element-tr"/>
                <div className="element-bl-rotated"/>
                <div className="main-content">
                    <img src={authChat} className='chat-img' alt="Chat"/>
                    <img src={authStatistics} className='stat-img' alt="Statistics"/>
                    <p>See statistics and anonymous comments
                        of your Webex meeting!</p>
                </div>
            </div>
        </StyleWrapper>
    );
}

export default SignUp;
