import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    padding-top: 10px;
    padding-right: 10px;
   
  .time-wrapper {
    padding-top: 19px;
    position: relative;
    &:after {
      content: '';
      display: flex;
      background: rgba(138, 83, 226, 0.15);
      width: 2px;
      height: 100%;
      position: absolute;
      right: 16px;
    }
    .time {
      font-family: 'Manrope', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 14px;
      position: relative;
      text-align: right;
      padding-right: 34px;
      color: rgba(136, 136, 136, 1);
    }
    
    .point {
      position: absolute;
      right: 7px;
      top: 50%;
      margin-top: -10px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: ${({ theme }) => theme.colors.primary};
      border: 2px solid #FFFFFF;
      box-shadow: 0 6px 20px rgba(29, 42, 68, 0.2);
    }
    
    width: 80px;
    min-width: 80px;
  }
  .emoji-wrapper {
    display: flex;
    padding-bottom: 4px;
    .emoji-box {
      display: flex;
      flex-direction: column;
      width: 34px;
      height: 54px;
      border-radius: 64px;
      padding: 8px 6px;
      background: #FFC632;
      margin-right: 4px;
      &.🤬 {
        background: ${({ theme }) => theme.colors['🤬']};
      }
      &.😡 {
        background: ${({ theme }) => theme.colors['😡']};
      }
      &.😠 {
        background: ${({ theme }) => theme.colors['😠']};
      }
      &.😐 {
        background: ${({ theme }) => theme.colors['😐']};
      }
      &.🙂 {
        background: ${({ theme }) => theme.colors['🙂']};
      }
      &.😀 {
        background: ${({ theme }) => theme.colors['😀']};
      }
      &.😃 {
        background: ${({ theme }) => theme.colors['😃']};
      }
      &:last-child {
        margin-right: 0;
      }
      .emoji {
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .count {
        font-family: 'Manrope', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: ${({ theme }) => theme.colors.secondary};
      }
    }
  }
  .messages-wrapper {
    text-align: left;
    .box-description {
        font-family: 'Manrope', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 14px;
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        color: rgba(136, 136, 136, 1);
        img {
            width: 16px;
            margin-right: 4px;
        }
    }
    .message-box {
      display: flex;
      background: ${({ theme }) => theme.colors.boxBg};
      color: ${({ theme }) => theme.colors.secondary};
      box-shadow: 0 6px 20px rgba(29, 42, 68, 0.12);
      border-radius: 10px;
      padding: 12px;
      margin-bottom: 4px;
      font-family: 'Manrope', sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 12px;
      line-height: 16px;
      width: fit-content;
      .emoji-box {
        border-radius: 5px;
        margin-right: 6px;
        
        &.undefined {
          display: none;
        }
        
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          top: 1px;
          font-size: 16px;
          width: 16px;
          height: 16px;
        }
      }
    }
  }
`;

export default Wrapper;