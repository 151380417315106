import styled from 'styled-components';

const StyleWrapper = styled.div`
  min-height: 100vh;
  .emotions-wrapper {
    padding: 10px 10px 0 10px;
    .emotions {
      height: 150px;
      padding: 10px 14px;
      background: ${({ theme }) => theme.colors.boxBg};
      box-shadow: 0 6px 20px rgba(37, 9, 71, 0.15);
      border-radius: 12px;

      .emoji-wrapper {
        display: flex;
        justify-content: center;
        padding-top: 10px;
        .emoji-box {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 14px;
          .range{
            display: flex;
            width: 100%;
            height: 60px;
            > div {
              margin-top: auto;
              position: relative;
              display: flex;
              width: 100%;
              background: rgba(133, 41, 173, 0.5);
              border-radius: 3px;
              min-width: 25px;
              &.🤬 {
                background: ${({ theme }) => theme.colors['🤬']};
              }
              &.😡 {
                background: ${({ theme }) => theme.colors['😡']};
              }
              &.😠 {
                background: ${({ theme }) => theme.colors['😠']};
              }
              &.😐 {
                background: ${({ theme }) => theme.colors['😐']};
              }
              &.🙂 {
                background: ${({ theme }) => theme.colors['🙂']};
              }
              &.😀 {
                background: ${({ theme }) => theme.colors['😀']};
              }
              &.😃 {
                background: ${({ theme }) => theme.colors['😃']};
              }
              span {
                position: absolute;
                bottom: calc(100% + 2px);
                left: 50%;
                transform: translateX(-50%);
                font-family: 'Manrope', sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;
                text-align: center;
                color: ${({ theme }) => theme.colors.secondary};
              }
            }
          }
          &:last-child {
            margin-right: 0;
          }
          .emoji {
            font-size: 25px;
            width: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 25px;
            position: relative;
            margin-top: 4px;
          }
        }
      }
    }
  }
  
  .infinite-scroll-component {
    > div {
      &:last-child {
        .time-wrapper {
          &:after {
            display: none;
          }
        }
      }
    }
  }

  .blank-chat {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc( 100vh - 64px );
    padding: 10px;
    > div {
      margin: 0;
    }
  }
`;

export default StyleWrapper;