import styled from 'styled-components';

const Wrapper = styled.div`
    box-shadow: 0px -6px 20px rgba(41, 29, 68, 0.08);
    background: ${({ theme }) => theme.colors.navBG};
    border-top-right-radius: 18px;
    border-top-left-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px 10px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    
    a {
        font-family: 'Manrope', sans-serif;
        margin-right: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 14px;
        color: #AEAEAE;

        &:last-child {
            margin-right: 0;
        }

       svg {
            path {
              fill: #AEAEAE;
            }
       }
      
       &.active {
          color: ${({ theme }) => theme.colors.primary};
         svg {
           path {
             fill: ${({ theme }) => theme.colors.primary};
           }
         }
       }
    }
`;

export default Wrapper;