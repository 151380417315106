import styled from 'styled-components';

const StyleWrapper = styled.div`
  
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 20px 64px 20px;
  overflow-x: hidden;

  .info-message {
    color: ${({ theme }) => theme.colors.secondary};
  } 
  
  .sub-title {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #AEAEAE;
  }
  
  .action-status-wrapper {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .action-status {
      position: relative;
      top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #FFFFFF;
      border: 1px solid #EDE5FB;
      border-radius: 8px;
      padding: 5px 16px;
      height: 30px;
      font-family: 'Manrope', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      color: #000000;
      img {
        width: 21px;
        margin-right: 10px;
      }
    }
  }
  
  .msg-wrapper {
    margin-top: auto;
    padding-bottom: 23px;
    
    .msg-controls {
      display: flex;
      padding-top: 8px;
      input {
        width: calc(100% - 52px);
        border: 2px solid ${({ theme }) => theme.colors.primary};
        background: ${({ theme }) => theme.colors.inputBg};
        color: ${({ theme }) => theme.colors.secondary};
        box-sizing: border-box;
        border-radius: 10px;
        outline: 0;
        height: 44px;
        padding: 5px 17px;
        font-style: normal;
        font-weight: 500;
        font-size: 16.675px;
        line-height: 23px;
        letter-spacing: 0.02em;
      }
      button {
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        margin-left: 8px;
        background: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

export default StyleWrapper;