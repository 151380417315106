import React, {useEffect, useState} from "react";

import {useNavigate, useSearchParams} from "react-router-dom";

import "firebase/firestore";

import StyleWrapper from './StyleWrapper.style';

import {analytics, db} from '../../firebase';

import config from "../../../src/Configs/config";

import logo from '../../Assets/Images/logo.png';
import { Formik } from "formik";
import {dark, light} from "../../Theme.style";

const validate = (values) => {
    let errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (values.email === '') {

    } else if (!regex.test(values.email)) {
        errors.email = "Invalid Email";
    }

    return errors;
};

function Welcome() {
    let [searchParams] = useSearchParams();
    const queryParams = Object.fromEntries([...searchParams]);

    if (queryParams && queryParams.meetingId) {
        localStorage.setItem('meetingId', `${queryParams.meetingId}`);
    }

    const [webexApp, setWebexApp] = useState(false);
    const email = localStorage.getItem('email');
    const [rules, setRules] = useState({
        allowComments: true,
        allowStatistics:true,
    });

    const isHost = localStorage.getItem('isHost');

    let navigate = useNavigate();

    useEffect(() => {

        const _webexApp = new window.Webex.Application();

        _webexApp.onReady().then(() => {
            setWebexApp(_webexApp);


            localStorage.setItem("current-theme", JSON.stringify(_webexApp.theme === 'DARK' ? dark : light));

            _webexApp.context
                .getUser()
                .then(user => {
                    localStorage.setItem('user', `${user.id}`);
                    _webexApp.context
                        .getMeeting()
                        .then(m => {
                            const { userRoles, conferenceId } = m;
                            const isHost = userRoles.includes('HOST');

                            localStorage.setItem('isHost', isHost);

                            if (queryParams && queryParams.meetingId) {
                                localStorage.setItem('meetingId', `${queryParams.meetingId}`);
                                analytics.logEvent(`webex_welcome_page_open`, { meetingId: queryParams.meetingId });
                            } else {
                                localStorage.setItem('meetingId', `${conferenceId}`);
                                analytics.logEvent(`webex_welcome_page_open`, { meetingId: conferenceId });

                                const meetingDocRef = db.collection("meetings")
                                    .doc(`${conferenceId}`);

                                meetingDocRef.get().then((doc) => {
                                    if (!doc.exists) {

                                        meetingDocRef.set({
                                            allowComments: true,
                                            allowStatistics:true,
                                        }).then(() => {
                                            localStorage.setItem('email', '');

                                            setRules({
                                                ...rules,
                                                allowComments: true,
                                                allowStatistics:true,
                                            });
                                        });
                                    }
                                });
                            }

                        })
                        .catch(error => {
                            console.log('getMeeting() promise failed with error', error.message);
                        });
                })
                .catch(error => {
                    console.log(`getUser() promise failed ${error.message}`);
                });
        });
    }, [webexApp]);


    useEffect(() => {
        const meetingId = localStorage.getItem('meetingId');

        const meetingDocRef = db.collection('meetings')
            .doc(`${meetingId}`);

        meetingDocRef.get().then((doc) => {
            if (doc.exists) {
                meetingDocRef.onSnapshot(snapshot => {
                    const {allowComments, allowStatistics} = snapshot.data();

                    setRules({...rules, allowComments, allowStatistics});
                });
            }
        });
    }, []);

    const changeRules = (rule) => {
        const meetingId = localStorage.getItem('meetingId');

        db.collection('meetings')
            .doc(`${meetingId}`).update(rule).then(() => {
            const {allowComments, allowStatistics} = rule;

            setRules({...rules, allowComments, allowStatistics });
        });
    }

    const submitForm = (values, actions) => {

        if (webexApp && values.email) {
            const meetingId = localStorage.getItem('meetingId');

            webexApp.context
                .getMeeting()
                .then(m => {
                    const { title, startTime, endTime } = m;

                    fetch(`${config.apiUrl}/public/webex/anilive/setInfoForStatistic`, {
                        method: 'POST',
                        body: JSON.stringify({
                            id: meetingId,
                            title,
                            email: values.email,
                            startTime,
                            endTime,
                        }),
                        headers: {
                            'Content-type': 'application/json; charset=UTF-8',
                        },
                    })
                        .then((response) => response.json())
                        .then((json) => console.log(json));
                })
                .catch(error => {
                    console.log('getMeeting() promise failed with error', error.message);
                });
        }

        localStorage.setItem('email', `${values.email}`);
        actions.setSubmitting(false);
        actions.resetForm({
            values: {
                email: values.email,
            },
        });

        const meetingId = localStorage.getItem('meetingId');

        analytics.logEvent(`webex_welcome_page_click_start_btn`, { meetingId: meetingId });

        navigate("/actions");
    };

    return (
        <StyleWrapper>
            {webexApp && <Formik
                initialValues={{ email: email || '' }}
                validate={validate}
                onSubmit={submitForm}
            >
                {(formik) => {
                    const {
                        values,
                        handleChange,
                        handleSubmit,
                        errors,
                        touched,
                        handleBlur,
                        isValid,
                        dirty,
                    } = formik;
                    return (
                        <form className="controls-group" onSubmit={handleSubmit}>
                            <div className="logo-wrapper">
                                <img width={193} src={logo} alt="AniVoice Lite" />
                                <p className='sub-logo'>AniLive Settings for your meeting</p>
                            </div>

                            <div className="controls-group">
                                <p className="label">Email to send statistics after the meeting</p>
                                <div className="field">
                                    <input type="email"
                                           name="email"
                                           id="email"
                                           value={values.email}
                                           onChange={handleChange}
                                           onBlur={handleBlur}
                                           className={
                                               errors.email ? "input-error" : null
                                           }
                                    />
                                    {errors.email &&  (
                                        <span className="error">{errors.email}</span>
                                    )}
                                </div>
                            </div>
                            {isHost === 'true' && <div className="controls-group controls">
                                <label>
                                    <p>Allow comments <span> for participants</span></p>
                                    <div className="checkbox-wrapper">
                                        <input type="checkbox" checked={rules.allowComments} onChange={e=> changeRules({allowComments: e.target.checked, allowStatistics: rules.allowStatistics })} />
                                        <span className="checkmark" />
                                    </div>
                                </label>
                                <label>
                                    <p>Allow statistics <span> for participants</span></p>
                                    <div className="checkbox-wrapper">
                                        <input type="checkbox" checked={rules.allowStatistics} onChange={e=> changeRules({allowStatistics: e.target.checked, allowComments: rules.allowComments })} />
                                        <span className="checkmark" />
                                    </div>
                                </label>
                            </div>}

                            <div className="controls-group">
                                <p className="label xs">You can always change these items in the settings.</p>
                                <button type='submit'>Let's Start</button>
                                <div className='text-center m-4'>
                                    <a className='mt-1' href="https://www.aniline.io/anilive" target="_blank" rel="noopener noreferrer">
                                        Learn More
                                    </a>
                                </div>
                            </div>
                        </form>
                        );
                }}
            </Formik> }
        </StyleWrapper>
    );
}

export default Welcome;
