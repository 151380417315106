import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";

import InfiniteScroll from 'react-infinite-scroll-component';
import moment from "moment";
import {Navigate, useNavigate} from "react-router-dom";

import { db } from "../../firebase";

import ChatMessageStatistic from "../../Components/ChatMesageStatistic/ChatMessageStatistic";

import StyleWrapper from './StyleWrapper.style';

import commentIcon from '../../Assets/Images/commentIcon.png';
import Header from "../../Layouts/Header/Header";

const kFormatter = (num) => {
    return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num);
};

function MeetingStatistic() {

    const navigate = useNavigate();
    let { meetingId } = useParams();
    const user = JSON.parse(localStorage.getItem('user'));


    const [messages, setMessages] = useState([]);
    const [emotions, setEmotions] = useState({
        '🤬': 0,
        '😡': 0,
        '😠': 0,
        '😐': 0,
        '🙂': 0,
        '😀': 0,
        '😃': 0,
        msgCount: 0,
    });
    const [ total, setTotal] = useState(0)
    const [lastVisible, setLastVisible] = useState(10);

    useEffect(() => {

        db.collection('meetings')
            .doc(`${meetingId}`).onSnapshot((doc) => {

            const statsObj = doc.data() ? doc.data() : {};

            const total = Object.values(statsObj).reduce((sum, item) => {
                return sum + item;
            }, 0);

            setTotal(total);

            setEmotions({
                ...emotions,
                "🤬": statsObj['🤬'] || 0,
                "😡": statsObj['😡'] || 0,
                "😠": statsObj['😠'] || 0,
                "😐": statsObj['😐'] || 0,
                "🙂": statsObj['🙂'] || 0,
                "😀": statsObj['😀'] || 0,
                "😃": statsObj['😃'] || 0,
                msgCount: statsObj.msgCount || 0,
            });
        });

    },[]);

    useEffect(() => {

        db.collection('meetings')
            .doc(`${meetingId}`)
            .collection('comments')
            .orderBy('createdAt', "desc")
            .limit(10)
            .onSnapshot( snapshot => {

                const arr = snapshot.docs.map( doc => {
                    const msgObj = doc.data();

                    return {
                        ...msgObj,
                        createdAt:  moment(msgObj.createdAt && msgObj.createdAt.toDate()).format('LT'),
                    };
                });

                setMessages(arr);
            });

    }, []);

    if (!user) {
        return <Navigate to="/" replace />;
    }

    const fetchMoreData = () => {

        db.collection('meetings')
            .doc(`${meetingId}`)
            .collection('comments')
            .orderBy('createdAt', "desc")
            .limit(lastVisible + 5)
            .onSnapshot( snapshot => {

                const arr = snapshot.docs.map( doc => {
                    const msgObj = doc.data();

                    return {
                        ...msgObj,
                        createdAt:  moment(msgObj.createdAt && msgObj.createdAt.toDate()).format('LT'),
                    };
                });

                setMessages(arr);
                setLastVisible(lastVisible + 5);
            });
    };

    return (
           <StyleWrapper>
               <Header />

               <div className='container mx-auto relative'>

                   <div onClick={()=> navigate(-1)} className='absolute -left-9 top-7 cursor-pointer'>
                       <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
                           <rect width="40" height="40" rx="8" fill="#8A53E1" fillOpacity="0.35"/>
                           <path d="M25.6 20H14.4" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                           <path d="M20 25.6L14.4 20L20 14.4" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                       </svg>
                   </div>

                   <div className="grid grid-cols-2 gap-4 pt-7">
                       <div className="emotions-wrapper">
                           <div className="emotions">
                               <p className="title">
                                   Meeting statistics
                               </p>
                               <div className="emoji-wrapper">
                                   {Object.keys(emotions).map((el, i) => {
                                       const countPercent = (emotions[el] / total) * 100;

                                       return <div className='emoji-box' key={el+i}>
                                           <div className='range'>
                                               <div style={{height: `${countPercent}%`}} className={el}>
                                                   <span>{kFormatter(emotions[el])}</span>
                                               </div>
                                           </div>
                                           <div className='emoji' >{el !== 'msgCount' ? el : <img width={25} src={commentIcon} alt="msg"/>}</div>
                                       </div>
                                   })}
                               </div>
                           </div>
                       </div>
                       <div>
                           <div
                               id="scrollableDiv"
                               style={{
                                   height: 'calc(100vh - 90px)',
                                   overflowY: 'auto',
                               }}
                           >
                               <InfiniteScroll
                                   dataLength={messages.length}
                                   next={fetchMoreData}
                                   hasMore={true}
                                   height={'calc(100vh - 90px)'}
                                   scrollableTarget="scrollableDiv"
                               >
                                   {messages.map((el, i) => {
                                       return <ChatMessageStatistic key={i} msg={el} />
                                   })}
                               </InfiniteScroll>
                           </div>
                       </div>
                   </div>
               </div>
           </StyleWrapper>
    );
};

export default MeetingStatistic;
