import React, {useEffect, useState} from "react";
import { ThemeProvider } from "styled-components";

import { Route, Routes, useSearchParams } from "react-router-dom";
import Actions from "./Containers/Actions/Actions";
import Statistics from "./Containers/Statistics/Statistics";
import GlobalStyle from "./GlobalStyles";

import { light, dark } from './Theme.style';
import Settings from "./Containers/Settings/Settings";
import Welcome from "./Containers/Welcom/Welcome";
import SignIn from "./Containers/Signin/SignIn";
import SignUp from "./Containers/Signup/SignUp";
import Protected from "./Components/ProtectedRoute/ProtectedRoute";
import Meetings from "./Containers/Meetings/Meetings";
import MeetingStatistic from "./Containers/MeetingStatistic/MeetingStatistic";

function App() {
    let [searchParams] = useSearchParams();

    const [selectedTheme, setSelectedTheme] = useState(dark);

    const [webexApp, setWebexApp] = useState(false);

    const queryParams = Object.fromEntries([...searchParams]);

    if (queryParams && queryParams.meetingId) {
        localStorage.setItem('meetingId', `${queryParams.meetingId}`);
    }

    useEffect(() => {
        const _webexApp = new window.Webex.Application();

        if(!webexApp) {
            setSelectedTheme(light);
        }

        _webexApp.onReady().then(() => {
            setWebexApp(_webexApp);
            setSelectedTheme(_webexApp.theme === 'DARK' ? dark : light);

            localStorage.setItem("current-theme", JSON.stringify(_webexApp.theme === 'DARK' ? dark : light));
            localStorage.setItem('email', '');

        });
    }, [webexApp]);

    useEffect(() => {
        const currentTheme = JSON.parse(localStorage.getItem("current-theme"));
        if (currentTheme) {
            setSelectedTheme(currentTheme);
        }
    }, []);

  return (
      <ThemeProvider theme={selectedTheme}>
          <GlobalStyle>
              <Routes>
                  <Route path="/" element={<SignIn />} />
                  <Route path="signin" element={<SignIn />} />
                  <Route path="signup" element={<SignUp />} />

                  <Route path="meetings" element={<Protected><Meetings /></Protected>}/>
                  <Route path="meetings/:meetingId" element={<MeetingStatistic />} />

                  <Route path="/actions"
                         element={
                                 <Actions />
                         }
                  />
                  <Route path="/statistics"
                         element={
                                 <Statistics />
                         }
                  />
                  <Route path="/settings"
                         element={
                                 <Settings />
                         }
                  />
                  <Route path="/welcome"
                         element={
                                 <Welcome />
                         }
                  />
                  <Route path="*" element={<Meetings />} />
              </Routes>
          </GlobalStyle>
      </ThemeProvider>

  );
}

export default App;
