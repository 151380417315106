import styled from 'styled-components';

const StyleWrapper = styled.div`
  padding-top: 60px;
  .item-grid {
    .period {
      .period-value {
        font-family: 'Manrope', sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 19px;
        color: #AAAAAA;
      }
      .m-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 15px;
        gap: 10px;
        width: 100%;
        height: 116px;
        background: #FFFFFF;
        box-shadow: 0 10px 24px rgba(29, 42, 68, 0.12);
        border-radius: 10px;
        cursor: pointer;
        border-image-slice: 5;
        border-width: 2px;
        border-color: transparent;
        &:hover {
          border-image-source: linear-gradient(120deg, rgba(138, 83, 226, 1)
          , rgba(222, 83, 225, 1)
          , rgba(204, 94, 110, 1)
          , rgba(232, 188, 102, 1)
          );
        }
        .c-title {
          font-family: 'Manrope', sans-serif;
          font-style: normal;
          font-weight: 800;
          font-size: 17px;
          line-height: 23px;
          text-align: center;
          color: #000000;
          margin-bottom: 10px;
          &.no-title {
            color: #888888;
          }
        }
        .c-date {
          font-family: 'Manrope', sans-serif;
          font-style: normal;
          font-weight: 800;
          font-size: 14px;
          line-height: 19px;
          text-align: center;
          color: #AAAAAA;
        }
      }
    }
  }
  
`;

export default StyleWrapper;