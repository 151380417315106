import React, {useEffect, useState, useMemo} from "react";

import firebase from "firebase/app";
import "firebase/firestore";

import Emoji from "../../Components/Emoji/Emogies";

import StyleWrapper from './StyleWrapper.style';

import { db } from '../../firebase';

import commentIcon from '../../Assets/Images/commentIcon.png';
import commentBigIcon from '../../Assets/Images/commentBigIcon.png';
import Navigation from "../../Layouts/Navigation/Navigation";
import RuleWarning from "../../Components/RuleWarning/RuleWarning";

import { Easing } from "react-native-web";
import Fly from "react-flying-objects";
import { useSearchParams } from "react-router-dom";
import { dark, light } from "../../Theme.style";

const DELAY = 100;
const DURATION = 2500;
const SIZE = 35;

const random = (min, max) => Math.floor(Math.random() * (max - min) + min);

function Actions() {

    let [searchParams] = useSearchParams();
    const queryParams = Object.fromEntries([...searchParams]);

    if (queryParams && queryParams.meetingId) {
        localStorage.setItem('meetingId', `${queryParams.meetingId}`);
    }

    const [msg, setMsg] = useState('');
    const [webexApp, setWebexApp] = useState(false);
    const [animatedEmoji, setAnimatedEmoji] = useState();
    const [flyingObjects, setFlyingObjects] = useState([]);
    const [show, setShow] = useState(false);
    const [counter, setCounter] = useState(10);

    const [rules, setRules] = useState({});

    const isHost = localStorage.getItem('isHost');

    useEffect(() => {
        const meetingId = localStorage.getItem('meetingId');

        db.collection('meetings')
            .doc(`${meetingId}`).onSnapshot(snapshot => {
            const {allowComments, allowStatistics} = snapshot.data();

            setRules({...rules, allowComments, allowStatistics});
        });
    }, []);

    useEffect(() => {
        let timer1;

        if (show && counter > 0) {
            timer1 = setTimeout(() => setCounter(counter - 1), 1000);
        }

        return () => {
            clearTimeout(timer1);
        };
    }, [counter, show]);

    useEffect(() => {
        let timer = setTimeout(() => {
            setShow(false);
            setCounter(10);
        }, 10000);

        return () => {
            clearTimeout(timer);
        };
    }, [show]);

    useEffect(() => {

        const _webexApp = new window.Webex.Application();

        _webexApp.onReady().then(() => {
            setWebexApp(_webexApp);

            localStorage.setItem("current-theme", JSON.stringify(_webexApp.theme === 'DARK' ? dark : light));

            _webexApp.context
                .getUser()
                .then(user => {
                    localStorage.setItem('user', `${user.id}`);
                    _webexApp.context
                        .getMeeting()
                        .then(m => {
                            const { userRoles, id } = m;
                            const isHost = userRoles.includes('HOST');

                            localStorage.setItem('isHost', isHost);

                            if (queryParams && queryParams.meetingId) {
                                localStorage.setItem('meetingId', `${queryParams.meetingId}`);
                            }

                        })
                        .catch(error => {
                            console.log('getMeeting() promise failed with error', error.message);
                        });
                })
                .catch(error => {
                    console.log(`getUser() promise failed ${error.message}`);
                });

            if (!_webexApp.isShared) {
                const meetingId = localStorage.getItem('meetingId');

                _webexApp
                    .clearShareUrl()
                    .then(() => {
                        console.log('clearShareUrl()', { message: 'share url has been cleared' });
                    })
                    .catch(error => {
                        console.log('clearShareUrl() failed with error', error);
                    });

                _webexApp
                    .setShareUrl(`${window.location.href}?meetingId=${meetingId}`, '', 'Aniline')
                    .then(() => {
                        console.log('setShareUrl()', {
                            message: 'shared url to participants panel',
                        });
                    })
                    .catch(error => {
                        console.log('setShareUrl() failed with error', error);
                    });
            }
            _webexApp
                .listen()
                .then(() => {
                    _webexApp.on('application:shareStateChanged', event => {
                        if (!event) {
                            const meetingId = localStorage.getItem('meetingId');

                            _webexApp
                                .clearShareUrl()
                                .then(() => {
                                    console.log('clearShareUrl()', { message: 'share url has been cleared' });
                                })
                                .catch(error => {
                                    console.log('clearShareUrl() failed with error', error);
                                });

                            _webexApp
                                .setShareUrl(`${window.location.href}?meetingId=${meetingId}`, '', 'Aniline')
                                .then(() => {
                                    console.log('setShareUrl()', {
                                        message: 'shared url to participants panel',
                                    });
                                })
                                .catch(error => {
                                    console.log('setShareUrl() failed with error', error);
                                });
                        }
                    });
                })
                .catch(function(reason) {
                    console.error(`listen: fail reason=${reason}`);
                });
        });
    }, [webexApp]);

    async function sendMessage( e, text, type ) {
        if (e) e.preventDefault();

        console.log(e);
        switch (e.detail) {
            case 1: {
                console.log('single click');
                break;
            }
            case 2: {
                console.log('double click');
                break;
            }
            case 3: {
                console.log('triple click');
                setShow(true);
                break;
            }
            default: {
                break;
            }
        }

        const meetingId = localStorage.getItem('meetingId');

        setAnimatedEmoji(<div style={{fontSize: '35px'}}>{ type === 'emoji' ? text : <img src={commentIcon} width={55} alt="icon"/>}</div>);


        const countObj = type === 'emoji' ? { [text]: firebase.firestore.FieldValue.increment(1) } : { msgCount: firebase.firestore.FieldValue.increment(1) }

        const stampNow = new Date();

        const utcTime = `${stampNow.getUTCHours()}${stampNow.getUTCMinutes()}`;

        const meetingDocRef = db.collection("meetings")
            .doc(`${meetingId}`);

        meetingDocRef.get().then((doc) => {
            if (doc.exists) {
                meetingDocRef.update(countObj).then();
            } else {
                meetingDocRef.set(countObj).then();
            }
        })

        const docRef = db.collection("meetings")
            .doc(`${meetingId}`)
            .collection('comments')
            .doc(utcTime);

        docRef.get().then((doc) => {
            if (doc.exists) {
                docRef.update({
                    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                }).then(() => {
                    docRef.collection('messages').add({
                        text,
                        type,
                        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                    });
                })

            } else {
                docRef.set({
                    docId: utcTime,
                    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                }).then(() => {
                    docRef.collection('messages').add({
                        text,
                        type,
                        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                    });
                });
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });

        setMsg('');
    }

    const objectConfig = useMemo(
        () => ({
            left: {
                fromValue: 0,
                toValue: 800,
                duration: DURATION,
                delay: DELAY
            },
            top: {
                fromValue: random(100, 200),
                toValue: random(100, 200),
                duration: DURATION,
                easing: Easing.elastic(5),
                delay: DELAY
            },
            width: {
                fromValue: random(SIZE - 10, SIZE + 10),
                toValue: SIZE,
                duration: DURATION,
                easing: Easing.elastic(5),
                delay: DELAY
            },
            height: {
                fromValue: random(SIZE - 10, SIZE + 10),
                toValue: SIZE,
                duration: DURATION,
                easing: Easing.elastic(5),
                delay: DELAY
            },
            opacity: {
                fromValue: 1,
                toValue: 0,
                duration: DURATION,
                easing: Easing.exp,
                delay: DELAY
            }
        }),
        [animatedEmoji]
    );

    return (
        <div>
            {Object.keys(rules).length !== 0 && webexApp  &&
                <StyleWrapper>
                <div className="action-status-wrapper" />
                <div className="main-text">
                    <h1 className='title text-center'>
                        Leave your reaction
                    </h1>

                    <p className="sub-title">
                        All reactions are anonymous
                    </p>
                </div>
                <div className="pb-4 relative">
                    {show && <div className='absolute w-full h-full bg-stone-400/[.02] z-[3] rounded-xl pt-3'>
                        <div>
                            <p className='text-center font-bold info-message'>You are sending reactions too quickly.</p>
                            <p className='text-center title'>{counter}</p>
                        </div>
                    </div>}
                    <div className={show ? 'blur-lg z-2': 'z-[2]'}>
                        <Emoji onClick={sendMessage} />
                    </div>
                </div>


                <Fly
                    objectToFly={animatedEmoji}
                    objectConfig={objectConfig}
                    flyingObjects={flyingObjects}
                    setFlyingObjects={setFlyingObjects}
                />

                { (isHost === 'true' || (rules && rules.allowComments)) ? <div className="msg-wrapper">
                        <p className="title text-left flex items-center"><img width='40px' height='40px' src={commentBigIcon} alt="Anonymous comment" className="mr-1.5"/>Anonymous comment</p>
                        <form className="msg-controls" onSubmit={(e) => sendMessage(e, msg, 'message')}>
                            <input type="text" value={msg} onChange={e => setMsg(e.target.value)} />
                            <button disabled={!msg}>
                                <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.71436 13.5714H14.5715C16.0871 13.5714 17.5405 12.9694 18.6121 11.8977C19.6838 10.8261 20.2858 9.37264 20.2858 7.85711C20.2858 6.34158 19.6838 4.88814 18.6121 3.81649C17.5405 2.74487 16.0871 2.14282 14.5715 2.14282H10.2858" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M6.00007 9.28564L1.71436 13.5714L6.00007 17.8571" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </button>
                        </form>
                    </div> :
                    <RuleWarning text={'The meeting host disabled comments'} />
                }

                <Navigation />
            </StyleWrapper>
            }
        </div>

    );
}

export default Actions;
