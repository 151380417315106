import React, { useEffect, useState } from "react";
import { Formik } from "formik";

import "firebase/firestore";

import config from "../../../src/Configs/config";

import StyleWrapper from './StyleWrapper.style';

import { db } from '../../firebase';
import Navigation from "../../Layouts/Navigation/Navigation";
import {useSearchParams} from "react-router-dom";
import {dark, light} from "../../Theme.style";
import RuleWarning from "../../Components/RuleWarning/RuleWarning";

const validate = (values) => {
    let errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.email) {
        errors.email = "Email is required";
    } else if (!regex.test(values.email)) {
        errors.email = "Invalid Email";
    }

    return errors;
};

function Settings() {
    let [searchParams] = useSearchParams();
    const queryParams = Object.fromEntries([...searchParams]);

    if (queryParams && queryParams.meetingId) {
        localStorage.setItem('meetingId', `${queryParams.meetingId}`);
    }
    const isHost = localStorage.getItem('isHost');
    const email = localStorage.getItem('email');

    const [webexApp, setWebexApp] = useState(false);
    const [formValues, setFormValues] = useState( );

    const [rules, setRules] = useState({});

    useEffect(() => {
        const meetingId = localStorage.getItem('meetingId');

        db.collection('meetings')
            .doc(`${meetingId}`).onSnapshot(snapshot => {
            const {allowComments, allowStatistics} = snapshot.data();

            setRules({...rules, allowComments, allowStatistics});
        });
    }, []);

    useEffect(() => {

        const _webexApp = new window.Webex.Application();

        _webexApp.onReady().then(() => {
            setWebexApp(_webexApp);

            localStorage.setItem("current-theme", JSON.stringify(_webexApp.theme === 'DARK' ? dark : light));

            _webexApp.context
                .getUser()
                .then(user => {
                    localStorage.setItem('user', `${user.id}`);
                    _webexApp.context
                        .getMeeting()
                        .then(m => {
                            const { userRoles, id } = m;
                            const isHost = userRoles.includes('HOST');

                            localStorage.setItem('isHost', isHost);

                            if (queryParams && queryParams.meetingId) {
                                localStorage.setItem('meetingId', `${queryParams.meetingId}`);
                            }
                        })
                        .catch(error => {
                            console.log('getMeeting() promise failed with error', error.message);
                        });
                })
                .catch(error => {
                    console.log(`getUser() promise failed ${error.message}`);
                });

            if (!_webexApp.isShared) {
                const meetingId = localStorage.getItem('meetingId');

                _webexApp
                    .clearShareUrl()
                    .then(() => {
                        console.log('clearShareUrl()', { message: 'share url has been cleared' });
                    })
                    .catch(error => {
                        console.log('clearShareUrl() failed with error', error);
                    });

                _webexApp
                    .setShareUrl(`${window.location.href}?meetingId=${meetingId}`, '', 'Aniline')
                    .then(() => {
                        console.log('setShareUrl()', {
                            message: 'shared url to participants panel',
                        });
                    })
                    .catch(error => {
                        console.log('setShareUrl() failed with error', error);
                    });
            }
            _webexApp
                .listen()
                .then(() => {
                    _webexApp.on('application:shareStateChanged', event => {
                        if (!event) {
                            const meetingId = localStorage.getItem('meetingId');

                            _webexApp
                                .clearShareUrl()
                                .then(() => {
                                    console.log('clearShareUrl()', { message: 'share url has been cleared' });
                                })
                                .catch(error => {
                                    console.log('clearShareUrl() failed with error', error);
                                });

                            _webexApp
                                .setShareUrl(`${window.location.href}?meetingId=${meetingId}`, '', 'Aniline')
                                .then(() => {
                                    console.log('setShareUrl()', {
                                        message: 'shared url to participants panel',
                                    });
                                })
                                .catch(error => {
                                    console.log('setShareUrl() failed with error', error);
                                });
                        }
                    });
                })
                .catch(function(reason) {
                    console.error(`listen: fail reason=${reason}`);
                });
        });
    }, [webexApp]);

    const changeRules = (rule) => {
        const meetingId = localStorage.getItem('meetingId');

        db.collection('meetings')
            .doc(`${meetingId}`).update(rule).then();
    }

    const submitForm = (values, actions) => {
        const meetingId = localStorage.getItem('meetingId');

        if (webexApp && values.email) {
            webexApp.context
                .getMeeting()
                .then(m => {
                    const { title, startTime, endTime } = m;

                    fetch(`${config.apiUrl}/public/webex/anilive/setInfoForStatistic`, {
                        method: 'POST',
                        body: JSON.stringify({
                            id: meetingId,
                            title,
                            email: values.email,
                            startTime,
                            endTime,
                        }),
                        headers: {
                            'Content-type': 'application/json; charset=UTF-8',
                        },
                    })
                        .then((response) => response.json())
                        .then((json) => console.log(json));
                })
                .catch(error => {
                    console.log('getMeeting() promise failed with error', error.message);
                });
        }

        localStorage.setItem('email', `${values.email}`);
        actions.setSubmitting(false);
        actions.resetForm({
            values: {
                email: values.email,
            },
        });
    };

    return (
        <div>
            {webexApp && <StyleWrapper>
                <div className='content-wrapper'>
                    <h1 className='title text-center'>
                        Meeting Settings
                    </h1>
                    {Object.keys(rules).length !== 0 && <div>
                        {(rules && rules.allowStatistics) ?
                            <Formik
                                initialValues={{ email: email || '' }}
                                validate={validate}
                                onSubmit={submitForm}
                            >
                                {(formik) => {
                                    const {
                                        values,
                                        handleChange,
                                        handleSubmit,
                                        errors,
                                        touched,
                                        handleBlur,
                                        isValid,
                                        dirty,
                                    } = formik;
                                    return (
                                        <form className="controls-group" onSubmit={handleSubmit}>
                                            <p className="label">Email to send statistics after the meeting</p>
                                            <div className="field">
                                                <input type="email"
                                                       name="email"
                                                       id="email"
                                                       value={values.email}
                                                       onChange={handleChange}
                                                       onBlur={handleBlur}
                                                       className={
                                                           errors.email && touched.email ? "input-error" : null
                                                       }
                                                />
                                                {errors.email && touched.email && (
                                                    <span className="error">{errors.email}</span>
                                                )}
                                            </div>
                                            <button
                                                type="submit"
                                                className={!(dirty && isValid) ? "disabled-btn" : ""}
                                                disabled={!(dirty && isValid)}
                                            >
                                                Save email
                                            </button>
                                        </form>)
                                }}
                            </Formik> :
                            <RuleWarning text={'The meeting host disabled statistic'} />
                        }
                    </div>}

                    {isHost === 'true' && <div className="controls-group">
                        <label>
                            <p>Allow comments <span> for participants</span></p>
                            <div className="checkbox-wrapper">
                                <input type="checkbox" checked={rules.allowComments} onChange={e=> changeRules({allowComments: e.target.checked})} />
                                <span className="checkmark" />
                            </div>
                        </label>
                        <label>
                            <p>Allow statistics <span> for participants</span></p>
                            <div className="checkbox-wrapper">
                                <input type="checkbox" checked={rules.allowStatistics} onChange={e=> changeRules({allowStatistics: e.target.checked})} />
                                <span className="checkmark" />
                            </div>
                        </label>
                    </div>}
                </div>

                <Navigation />

            </StyleWrapper> }
        </div>
    );
}

export default Settings;
