import React, { useEffect, useState } from "react";

import { Navigate, useNavigate } from "react-router-dom";

import "firebase/firestore";

import config from "../../../src/Configs/config";

import StyleWrapper from './StyleWrapper.style';

import Header from "../../Layouts/Header/Header";
import {auth} from "../../firebase";

function Meetings() {
    let navigate = useNavigate();
    const [ meetingsList, setMeetingsList ] = useState(null);

    useEffect(() => {
        auth.onAuthStateChanged(authUser => {
            if(authUser) {
                authUser.getIdTokenResult(true).then(function(idTokenResult) {
                    localStorage.setItem('user', JSON.stringify(idTokenResult));
                });
            } else {
                navigate("/");
            }
        });
    }, [navigate]);

    const user = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        if (user) {
            fetchMeetings(user.claims.email);
        }
    }, [JSON.stringify(user)]);

    if (!user) {
        return <Navigate to="/" replace />;
    }

    const fetchMeetings = email => {

        fetch(`${config.apiUrl}/anilive/get-statistics?email=${encodeURIComponent(email)}`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${user.token}`,
            },
        })
            .then((response) => response.json())
            .then((json) => {
                setMeetingsList(json.data)
            })
            .catch(error => {
                console.log('error ====>>', error);
            });
    };

    const onMeetingDetail = meetingId => {
        navigate(`${meetingId}`, { replace: false });
    };

    return (
        <StyleWrapper>
            <Header />
            <div className='container mx-auto pt-8'>
                <h1 className="title pb-6">Meeting Reports</h1>
                <div className="item-grid flex flex-wrap">
                    {meetingsList && meetingsList.map(el => {
                        const { meetings, period } = el;

                        return <div className='period w-full' key={period}>
                            <div className="period-value mb-2.5">
                                { period }
                            </div>
                            <div className="grid grid-cols-4 gap-4 mb-4">
                                {meetings.length && meetings.map(it => {
                                    const { name, date, meetingId } = it;

                                    return <div className='m-card' key={meetingId} onClick={() => onMeetingDetail(meetingId)}>
                                        <p className={name === 'Meeting name is not public' ? "c-title no-title" : "c-title" }>
                                            {name}
                                        </p>
                                        <p className="c-date">
                                            {date}
                                        </p>
                                    </div>
                                })}
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </StyleWrapper>
    )
}

export default Meetings;
