import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 16px;
   > div {
     margin-right: 11px;
     font-size: 36px;
     height: 37px;
     width: 37px;
     display: flex;
     align-items: center;
     justify-content: center;
     cursor: pointer;
     &:last-child {
       margin-right: 0;
     }
   }
`;

export default Wrapper;