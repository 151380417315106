import React from "react";

import { Link, useNavigate } from "react-router-dom";

import Wrapper from './Wrapper.style';

import logo from '../../Assets/Images/logo.png';
import firebase from "firebase";
import { analytics } from "../../firebase";

function Header() {
    const navigate = useNavigate();

    const user = JSON.parse(localStorage.getItem('user'));

    const signOut = () => {
        firebase.auth().signOut().then(() => {
            localStorage.clear();
            analytics.logEvent('sign_out');

            navigate("/");
        });
    }

    return (
        <Wrapper>
            <div className='container mx-auto'>
                <div className="header flex justify-between">
                    <Link to='/meetings'>
                        <img src={logo} width={122} alt="AniLive"/>
                    </Link>
                    <div className="user-panel">
                        <div className="user-info">
                            <div className="email">{user && user.claims.email}</div>
                        </div>
                        <button className='logout' onClick={signOut}>
                            <svg width="15" height="16" viewBox="0 0 15 16" fill="none" className='mr-2.5'>
                                <path d="M5.32 14.48H2.44C1.64471 14.48 1 13.8353 1 13.04V2.96002C1 2.16473 1.64471 1.52002 2.44 1.52002H5.32" stroke="#DF3838" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M10.3601 11.5999L13.9601 7.9999L10.3601 4.3999" stroke="#DF3838" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M13.96 8.00014H5.32001" stroke="#DF3838" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            Log out
                        </button>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}

export default Header;
