import React, { useEffect } from "react";

import {useNavigate, Link, useSearchParams} from "react-router-dom";

import StyleWrapper from './StyleWrapper.style';

import { auth, analytics } from '../../firebase';
import firebase from "firebase/app";

import logo from '../../Assets/Images/logo.png';
import authChat from '../../Assets/Images/authChat.png';
import authStatistics from '../../Assets/Images/authStatistics.png';

import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

function SignIn() {
    const navigate = useNavigate();
    const user = localStorage.getItem('user');

    let [searchParams] = useSearchParams();
    const { token } = Object.fromEntries([...searchParams]);

    if (user && !token) {
        navigate("/meetings");
    }

    if(token) {
        localStorage.clear();
        firebase.auth().signOut().then(()=> firebase.auth().signInWithCustomToken(token).then());
    }

    useEffect(() => {
        auth.onAuthStateChanged(authUser => {
            if(authUser) {
                authUser.getIdTokenResult().then(function(idTokenResult) {
                    localStorage.setItem('user', JSON.stringify(idTokenResult));
                    analytics.logEvent('login');

                    navigate("/meetings");
                });
            }
        });
    }, [navigate]);

    const logInWithEmailAndPassword = ( fields, actions ) => {
        const { email, password } = fields;

        firebase.auth().signInWithEmailAndPassword(email, password)
            .then(() => {
                actions.setSubmitting(false);
            })
            .catch((error) => {
                const errorMessage = error.message;

                actions.setSubmitting(false);
                actions.setErrors({request: errorMessage});
            });
    };

    return (
        <StyleWrapper>
            {!token && <>
                <div className="side form-side">
                    <div className="form-wrapper">
                        <img src={logo} alt="AniLive" width={190}/>
                        <div className="form-header flex justify-between items-center">
                            <h1 className='title'>Login</h1>
                            <Link to='/signup'>Sign Up</Link>
                        </div>
                        <Formik
                            initialValues={{
                                email: '',
                                password: '',
                            }}
                            validationSchema={Yup.object().shape({
                                email: Yup.string()
                                    .email('Email is invalid')
                                    .required('Email is required'),
                                password: Yup.string()
                                    .min(8, 'Password must be at least 8 characters')
                                    .required('Password is required')
                            })}
                            onSubmit={(fields, actions) => {
                                actions.setSubmitting(true);
                                logInWithEmailAndPassword(fields, actions);
                            }}
                        >
                            {({
                                  errors,
                                  touched,
                                  isSubmitting
                              }) => (
                                <Form>
                                    <div className="form-group">
                                        <label htmlFor="email">Emails <span className='asterisk'>*</span></label>
                                        <Field name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password">Password <span className='asterisk'>*</span></label>
                                        <Field name="password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                        <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="form-group pt-2.5">
                                        <button type="submit" disabled={isSubmitting} className="btn btn-primary mr-2">
                                        <span>
                                            {isSubmitting && <div className="loading" /> }
                                            Login
                                        </span>
                                        </button>
                                    </div>

                                    <p className="form-warning">
                                        {errors && errors.request}
                                    </p>

                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
                <div className="side banner-side">
                    <div className="element-tl"/>
                    <div className="element-tr"/>
                    <div className="element-bl-rotated"/>
                    <div className="main-content">
                        <img src={authChat} className='chat-img' alt="Chat"/>
                        <img src={authStatistics} className='stat-img' alt="Statistics"/>
                        <p>See statistics and anonymous comments
                            of your Webex meeting!</p>
                    </div>
                </div>
            </>}
        </StyleWrapper>
    );
}

export default SignIn;
