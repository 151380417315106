import React, {useEffect, useState} from "react";

import InfiniteScroll from 'react-infinite-scroll-component';
import moment from "moment";

import { db } from "../../firebase";

import ChatMessage from "../../Components/ChatMesage/ChatMessage";

import StyleWrapper from './StyleWrapper.style';

import commentIcon from '../../Assets/Images/commentIcon.png';
import Navigation from "../../Layouts/Navigation/Navigation";
import RuleWarning from "../../Components/RuleWarning/RuleWarning";
import {useSearchParams} from "react-router-dom";
import {dark, light} from "../../Theme.style";


function Statistics() {

    let [searchParams] = useSearchParams();
    const queryParams = Object.fromEntries([...searchParams]);

    if (queryParams && queryParams.meetingId) {
        localStorage.setItem('meetingId', `${queryParams.meetingId}`);
    }

    const [messages, setMessages] = useState([]);
    const [webexApp, setWebexApp] = useState(false);
    const [emotions, setEmotions] = useState({
        '🤬': 0,
        '😡': 0,
        '😠': 0,
        '😐': 0,
        '🙂': 0,
        '😀': 0,
        '😃': 0,
        msgCount: 0,
    });
    const [ total, setTotal] = useState(0)
    const [lastVisible, setLastVisible] = useState(10);
    const [rules, setRules] = useState({});

    const isHost = localStorage.getItem('isHost');

    useEffect(() => {
        const meetingId = localStorage.getItem('meetingId');

        db.collection('meetings')
            .doc(`${meetingId}`).onSnapshot(snapshot => {
            const {allowComments, allowStatistics} = snapshot.data();

            setRules({...rules, allowComments, allowStatistics});
        });
    }, []);

    useEffect(() => {

        const _webexApp = new window.Webex.Application();

        _webexApp.onReady().then(() => {
            setWebexApp(_webexApp);

            localStorage.setItem("current-theme", JSON.stringify(_webexApp.theme === 'DARK' ? dark : light));

            _webexApp.context
                .getUser()
                .then(user => {
                    localStorage.setItem('user', `${user.id}`);
                    _webexApp.context
                        .getMeeting()
                        .then(m => {
                            const { userRoles, id } = m;
                            const isHost = userRoles.includes('HOST');

                            localStorage.setItem('isHost', isHost);

                            if (queryParams && queryParams.meetingId) {
                                localStorage.setItem('meetingId', `${queryParams.meetingId}`);
                            }

                        })
                        .catch(error => {
                            console.log('getMeeting() promise failed with error', error.message);
                        });
                })
                .catch(error => {
                    console.log(`getUser() promise failed ${error.message}`);
                });

            if (!_webexApp.isShared) {
                const meetingId = localStorage.getItem('meetingId');

                _webexApp
                    .clearShareUrl()
                    .then(() => {
                        console.log('clearShareUrl()', { message: 'share url has been cleared' });
                    })
                    .catch(error => {
                        console.log('clearShareUrl() failed with error', error);
                    });

                _webexApp
                    .setShareUrl(`${window.location.href}?meetingId=${meetingId}`, '', 'Aniline')
                    .then(() => {
                        console.log('setShareUrl()', {
                            message: 'shared url to participants panel',
                        });
                    })
                    .catch(error => {
                        console.log('setShareUrl() failed with error', error);
                    });
            }
            _webexApp
                .listen()
                .then(() => {
                    _webexApp.on('application:shareStateChanged', event => {
                        if (!event) {
                            const meetingId = localStorage.getItem('meetingId');

                            _webexApp
                                .clearShareUrl()
                                .then(() => {
                                    console.log('clearShareUrl()', { message: 'share url has been cleared' });
                                })
                                .catch(error => {
                                    console.log('clearShareUrl() failed with error', error);
                                });

                            _webexApp
                                .setShareUrl(`${window.location.href}?meetingId=${meetingId}`, '', 'Aniline')
                                .then(() => {
                                    console.log('setShareUrl()', {
                                        message: 'shared url to participants panel',
                                    });
                                })
                                .catch(error => {
                                    console.log('setShareUrl() failed with error', error);
                                });
                        }
                    });
                })
                .catch(function(reason) {
                    console.error(`listen: fail reason=${reason}`);
                });
        });
    }, [webexApp]);

    useEffect(() => {
        const meetingId = localStorage.getItem('meetingId');


        db.collection('meetings')
            .doc(`${meetingId}`).onSnapshot((doc) => {

            const statsObj = doc.data() ? doc.data() : {};

            const total = Object.values(statsObj).reduce((sum, item) => {
                return sum + item;
            }, 0);

            setTotal(total);

            setEmotions({
                ...emotions,
                "🤬": statsObj['🤬'] || 0,
                "😡": statsObj['😡'] || 0,
                "😠": statsObj['😠'] || 0,
                "😐": statsObj['😐'] || 0,
                "🙂": statsObj['🙂'] || 0,
                "😀": statsObj['😀'] || 0,
                "😃": statsObj['😃'] || 0,
                msgCount: statsObj.msgCount || 0,
            });
        });

    },[]);

    useEffect(() => {
        const meetingId = localStorage.getItem('meetingId');


        db.collection('meetings')
            .doc(`${meetingId}`)
            .collection('comments')
            .orderBy('createdAt', "desc")
            .limit(10)
            .onSnapshot( snapshot => {

                const arr = snapshot.docs.map( doc => {
                    const msgObj = doc.data();

                    return {
                        ...msgObj,
                        createdAt:  moment(msgObj.createdAt && msgObj.createdAt.toDate()).format('LT'),
                    };
                });

                setMessages(arr);
            });

    }, []);

    const fetchMoreData = () => {
        const meetingId = localStorage.getItem('meetingId');


        db.collection('meetings')
            .doc(`${meetingId}`)
            .collection('comments')
            .orderBy('createdAt', "desc")
            .limit(lastVisible + 5)
            .onSnapshot( snapshot => {

                const arr = snapshot.docs.map( doc => {
                    const msgObj = doc.data();

                    return {
                        ...msgObj,
                        createdAt:  moment(msgObj.createdAt && msgObj.createdAt.toDate()).format('LT'),
                    };
                });

                setMessages(arr);
                setLastVisible(lastVisible + 5);
            });
    };

    const kFormatter = (num) => {
        return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num);
    };

    return (
       <div>
           {webexApp && <StyleWrapper>
               {Object.keys(rules).length !== 0 && <div>
                   { (isHost === 'true' || (rules && rules.allowStatistics)) ?
                       <div>
                           <div className="emotions-wrapper">
                               <div className="emotions">
                                   <p className="title">
                                       Meeting statistics
                                   </p>
                                   <div className="emoji-wrapper">
                                       {Object.keys(emotions).map(el => {
                                           const countPercent = (emotions[el] / total) * 100;

                                           return <div className='emoji-box'>
                                               <div className='range'>
                                                   <div style={{height: `${countPercent}%`}} className={el}>
                                                       <span>{kFormatter(emotions[el])}</span>
                                                   </div>
                                               </div>
                                               <div className='emoji' >{el !== 'msgCount' ? el : <img width={25} src={commentIcon} alt="msg"/>}</div>
                                           </div>
                                       })}
                                   </div>
                               </div>
                           </div>
                           <div>
                               <div
                                   id="scrollableDiv"
                                   style={{
                                       height: 'calc(100vh - 224px)',
                                       overflowY: 'auto',
                                   }}
                               >
                                   <InfiniteScroll
                                       dataLength={messages.length}
                                       next={fetchMoreData}
                                       hasMore={true}
                                       height={'calc(100vh - 224px)'}
                                       scrollableTarget="scrollableDiv"
                                   >
                                       {messages.map((el, i) => {
                                           return <ChatMessage key={i} msg={el} />
                                       })}
                                   </InfiniteScroll>
                               </div>
                           </div>
                       </div>
                       :
                       <div className='blank-chat'>
                           <RuleWarning text={'The meeting host disabled meeting statistics'} />
                       </div>
                   }
               </div>}

               <Navigation />
           </StyleWrapper>}
       </div>
    );
}

export default Statistics;
